import React from "react"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Footer from "../components/sections/footer"
import styled from "styled-components"

const LoginMobilePage = () => {
  return (
    <Layout>
      <LoginMobilePageStyle>
        <SEO title="ZIOOM" />
        <div className="logo">
          <span className="logo__text">ZIOOM</span>
          <div className="logo__dot"></div>
        </div>
        <p className="title">Logowanie</p>
        <p className="desc">
          Do pracy z programem potrzebujesz urządzenia o nieco większej
          <br />
          rozdzielczości niż Twoje urządzenie mobilne.
          <br />
          <br /> W kolejnych wydaniach przygotujemy również wersją dla urządzeń
          <br />
          mobilnych.
        </p>
        <Footer />
      </LoginMobilePageStyle>
    </Layout>
  )
}

export default LoginMobilePage

const LoginMobilePageStyle = styled.div`
  padding: 16px 24px 0 24px;

  .title {
    margin: 58px 0 0 0;
    font-family: ${props => props.theme.font.primary.regular};
    font-size: 28px;
    line-height: 1.3;
    letter-spacing: 0.2;
    color: ${props => props.theme.color.primary.high};
    text-align: center;
  }

  .desc {
    margin: 42px 0 148px 0;
    font-family: ${props => props.theme.font.primary.regular};
    font-size: ${props => props.theme.font_size.xsmall};
    line-height: 1.75;
    color: ${props => props.theme.color.primary.high};
    text-align: center;
  }
`
